import React from "react"
import PropTypes from "prop-types"
import { isEmpty, has } from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"

const Partners = ({ title, /*description,*/ partners }) => (
  <div className="shell">
    {title && (
      <header className="section-partners-head">
        <h1 dangerouslySetInnerHTML={{ __html: title + `<span class="dotRed">.</span>`}} />
      </header>
    )}
    {/* {description && (
      <div>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    )} */}
    {!isEmpty(partners) && (
      <div className="partners-gallery">
        {partners.map((partner, index) => (
          <div className="partner-item" key={index}>
            {has(partner, "image", "caption") && (
              <GatsbyImage
                image={partner.image.childImageSharp.gatsbyImageData}
                alt={partner.caption}
              />
            )}
          </div>
        ))}
      </div>
    )}
  </div>
)

Partners.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  partners: PropTypes.array,
}

Partners.defaultProps = {
  title: PropTypes.string,
  description: PropTypes.string,
  partners: [],
}

export default Partners
