import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import Outro from "../components/outro"
import Partners from "../components/partners-page/partners"
import Seo from "../components/seo"

const PartnersPage = ({ data }) => {
  const { title, description, partners /*outro*/ } = data.mdx.frontmatter

  return (
    <Layout>
      <Seo title={title} description={description} />
      {partners && (
        <Partners
          key="#partners"
          partners={partners}
          title={title}
          description={description}
        />
      )}
      {/* {outro && <Outro key="#outro" {...outro} />} */}
    </Layout>
  )
}

PartnersPage.propTypes = {
  data: PropTypes.object,
}

export default PartnersPage

export const pageQuery = graphql`
  query PartnersTemplate($locale: String!) {
    mdx(
      fileAbsolutePath: { regex: "/static-pages/partners/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        partners {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          caption
        }
        outro {
          title
          buttonLeft {
            link
            caption
          }
        }
      }
    }
  }
`
